.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500;700;800;900&display=swap");

:root {
  --myfont: "Raleway-medium", Lato, sans-serif;
  --myfontBold: "Raleway-extra-bold", Lato, sans-serif;
  /*--myfont: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;*/
}

.body {
  font-family: var(--myfont);
  font-weight: 500;
}

body {
  overflow-x: auto;
  padding: 10px;
  background-color: steelblue;
}

.ui.header {
  font-family: var(--myfontBold);
  font-weight: 900;
}

.ui.button {
  font-family: var(--myfontBold);
  font-weight: 800;
}

.ui.label {
  font-family: var(--myfontBold);
  font-weight: 800;
}

.ui.mini.statistic > .value {
  font-family: var(--myfontBold);
  font-weight: 800;
}

.ui.container {
  font-family: var(--myfont);
  font-weight: 500;
  max-width: 103% !important;
}

.ui.basic.button {
  transition: 0.3s;
  color: snow !important;
  font-family: var(--myfontBold);
  font-weight: 800;
  box-shadow: 0 0 0 3px rgba(34, 36, 38, 0.25) inset;
}

.ui.basic.button:hover {
  background-color: gold !important;
  color: black !important;
}

.ui.basic.button:focus {
  background-color: gold !important;
  color: black !important;
}

.ui.button {
  transition: 0.3s;
}

.ui.button:hover {
  background-color: #2185d0 !important;
  color: #fff;
}

.ui.compact.button {
  transition: 0.3s;
}

.ui.compact.button:hover {
  background-color: #2185d0 !important;
  color: #fff;
}

i.huge.icon {
  color: gold !important;
}

.ui.segment {
  background-color: snow;
}

.ui.tiny.label,
.ui.tiny.labels .label {
  font-size: 0.77428571rem;
}

.ui.label,
.ui.labels .label {
  font-size: 0.96714286rem;
}

.ui.small.image {
  width: 175px;
}

.ui.bordered.image img,
.ui.bordered.image svg,
.ui.bordered.images .image,
.ui.bordered.images img,
.ui.bordered.images svg,
img.ui.bordered.image {
  border: 8px solid rgba(0, 0, 0, 0.3);
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
